<svg id="overlay" *ngIf="step" (click)="$event.stopPropagation()">
  <defs>
    <mask id="overlay-mask">
      <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
      <rect
        id="highlight"
        rx="5"
        [attr.x]="highlightLeft"
        [attr.y]="highlightTop"
        [attr.width]="highlightWidth"
        [attr.height]="highlightHeight"
        fill="black"></rect>
    </mask>
  </defs>
  <rect
    x="0"
    y="0"
    width="100vw"
    height="100vh"
    fill="black"
    fill-opacity="0.4"
    mask="url(#overlay-mask)"></rect>
</svg>

<article
  class="container"
  [ngStyle]="{
    top: top,
    left: left,
    bottom: bottom
  }"
  #tour
  [hidden]="!step"
  [ngClass]="[class]">
  <header>
    <h2>{{ step?.title }}</h2>
    <button
      app-icon-button
      src="assets/images/icons/close.svg"
      alt="Fermer"
      (click)="close(false)"></button>
  </header>
  <main>
    {{ step?.content }}
  </main>
  <span class="arrow"></span>
  <footer>
    <nav>
      <a
        role="button"
        (click)="previous()"
        [ngClass]="{ disabled: index === 0 }"
        ><app-icon src="assets/images/icons/previous.svg"></app-icon>
        Précédent</a
      >
      <span>{{ index + 1 }}/{{ max }}</span>
      <a role="button" *ngIf="index + 1 < max" (click)="next()"
        >Suivant <app-icon src="assets/images/icons/next.svg"></app-icon>
      </a>
      <a role="button" *ngIf="index + 1 >= max" (click)="close(true)">
        <strong>Terminer</strong>
      </a>
    </nav>
  </footer>
</article>
