import { Injectable } from '@angular/core';
import { StorageService, storageKey } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements StorageService {
  /**
   * Retrieve an item from session storage
   */
  public get(key: string): string | null {
    key = storageKey(key);
    return sessionStorage.getItem(key);
  }

  /**
   * Sets an item in session storage
   */
  public set(key: string, value: string): void {
    key = storageKey(key);
    sessionStorage.setItem(key, value);
  }

  /**
   * Remove an item from session storage
   */
  public remove(key: string): void {
    key = storageKey(key);
    sessionStorage.removeItem(key);
  }

  /**
   * Clear session
   */
  public clear(): void {
    sessionStorage.clear();
  }
}
