import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, NgZone, TemplateRef } from '@angular/core';
import { bodyScroll } from '../utils/html.utils';

enum DialogAnim {
  Popin = 'popin',
}

@Injectable({
  providedIn: 'root',
})
export class AppDialogService {
  public constructor(
    private readonly _dialog: Dialog,
    private readonly _ngZone: NgZone
  ) {}

  public openPopin<R = unknown, D = unknown, C = unknown>(
    componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
    data?: D
  ): DialogRef<R, C> {
    return this.open(
      componentOrTemplateRef,
      {
        panelClass: 'popin',
        hasBackdrop: true,
        data,
      },
      DialogAnim.Popin,
      DialogAnim.Popin
    );
  }

  private open<R = unknown, D = unknown, C = unknown>(
    componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
    config?: DialogConfig<D, DialogRef<R, C>>,
    show?: DialogAnim,
    hide?: DialogAnim
  ): DialogRef<R, C> {
    const ref: DialogRef<R, C> = this._dialog.open(
      componentOrTemplateRef,
      config
    );

    if (show || hide) {
      // We show  the last added overlay
      const wrappers: HTMLCollectionOf<Element> =
        document.getElementsByClassName('cdk-overlay-pane');
      const wrapper: HTMLElement = wrappers[wrappers.length - 1] as HTMLElement;

      if (show) {
        wrapper.animate(this.animation(show, true), this.options(true));
        bodyScroll(false);
      }

      if (hide) {
        const closeFunction: () => void = ref.close;
        const closeHandler = (dialogResult?: R): void => {
          const animation: Animation = wrapper.animate(
            this.animation(hide, false),
            this.options(false)
          );
          animation.onfinish = (): void => {
            (wrapper as HTMLElement).style.display = 'none';
            this._ngZone.run(() => ref.close(dialogResult));
            bodyScroll(true);
          };
          ref.close = closeFunction;
        };
        ref.close = (dialogResult?: R): void => closeHandler(dialogResult);
      }
    }

    return ref;
  }

  private animation(direction: DialogAnim, show: boolean): Keyframe[] {
    switch (direction) {
      case DialogAnim.Popin:
        return show
          ? [
              { transform: 'scale(0.7)', opacity: '0' },
              { transform: 'scale(1)', opacity: '1' },
            ]
          : [{ opacity: '1' }, { opacity: '0' }];
    }
  }

  private options(show: boolean): KeyframeAnimationOptions {
    return show
      ? {
          duration: 200,
          easing: 'ease-out',
        }
      : {
          duration: 150,
          easing: 'ease-out',
          fill: 'forwards',
        };
  }
}
