import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { AbstractDialogComponent } from '../abstract-dialog.component';

export interface ConfirmOptions {
  title: string;
  content: string;
  validate: string;
  cancel: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent extends AbstractDialogComponent<boolean> {
  public constructor(
    dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public readonly options: ConfirmOptions
  ) {
    super(dialogRef);
  }
}
