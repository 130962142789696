<section>
  <header>
    <app-icon *ngIf="icon" [src]="icon"></app-icon>
    <h2>{{ label }}</h2>
    <span class="spacer"></span>
    <button
      app-icon-button
      src="assets/images/icons/close.svg"
      alt="Fermer"
      (click)="dismiss.emit()"></button>
  </header>
  <div class="content">
    <ng-content></ng-content>
  </div>
</section>
