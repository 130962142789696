import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Segments } from './shared/constants/routes/routes.constant';

const routes: Routes = [
  {
    path: Segments.home,
    loadChildren: () =>
      import('./modules/home/home.module').then(
        (m: typeof import('./modules/home/home.module')) => m.HomeModule
      ),
  },
  {
    path: Segments.companies,
    loadChildren: () =>
      import('./modules/companies/companies.module').then(
        (m: typeof import('./modules/companies/companies.module')) =>
          m.CompaniesModule
      ),
  },
  {
    path: Segments.employees,
    loadChildren: () =>
      import('./modules/employees/employees.module').then(
        (m: typeof import('./modules/employees/employees.module')) =>
          m.EmployeeModule
      ),
  },
  {
    path: Segments.workStudy,
    loadChildren: () =>
      import('./modules/work-study/work-study.module').then(
        (m: typeof import('./modules/work-study/work-study.module')) =>
          m.WorkStudyModule
      ),
  },
  {
    path: Segments.interns,
    loadChildren: () =>
      import('./modules/interns/interns.module').then(
        (m: typeof import('./modules/interns/interns.module')) =>
          m.InternsModule
      ),
  },
  {
    path: Segments.students,
    loadChildren: () =>
      import('./modules/students/students.module').then(
        (m: typeof import('./modules/students/students.module')) =>
          m.StudentsModule
      ),
  },
  {
    path: Segments.selfEmployed,
    loadChildren: () =>
      import('./modules/self-employed/self-employed.module').then(
        (m: typeof import('./modules/self-employed/self-employed.module')) =>
          m.SelfEmployedModule
      ),
  },
  {
    path: Segments.legalNotice,
    loadChildren: () =>
      import('./modules/legal-notice/legal-notice.module').then(
        (m: typeof import('./modules/legal-notice/legal-notice.module')) =>
          m.LegalNoticeModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
