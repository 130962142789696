import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Webservices } from '../../constants/routes/webservices.constant';
import { AbstractHttpService, Response } from './abstract-http.service';
import { BootstrapDto } from '../../models/dto/bootstrap-dto';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class BootstrapHttpService extends AbstractHttpService {
  protected constructor(_http: HttpClient, _config: ConfigService) {
    super(_http, _config);
  }

  public bootstrap(): Observable<BootstrapDto | null> {
    return this.get<BootstrapDto>(Webservices.bootstrap, {
      response: Response.json,
    });
  }
}
