import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'app-popin-layout',
  templateUrl: './popin-layout.component.html',
  styleUrls: ['./popin-layout.component.scss'],
})
export class PopinLayoutComponent extends AbstractComponent {
  @Input() public icon?: string;
  @Input() public label?: string;

  @Output() public readonly dismiss: EventEmitter<void>;

  public constructor() {
    super();

    this.dismiss = new EventEmitter<void>();
  }
}
