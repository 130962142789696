import { ElementRef, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { UiTourDirective } from '../directives/ui-tour.directive';
import { AppRouterService } from './app-router.service';
import { Routes } from '../constants/routes/routes.constant';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { AppStateService } from './app-state.service';
import { ResponsiveService } from './html/responsive.service';

export interface TourStep {
  key: string;
  title: string;
  content: string[];
  delay?: number;
  type: 'all' | 'desktop' | 'mobile';
  preCallback?: () => Observable<null>;
  restoreCallBack?: () => Observable<null>;
}

@Injectable({
  providedIn: 'root',
})
export class UiTourService {
  private elements: Map<string, UiTourDirective> = new Map<
    string,
    UiTourDirective
  >();
  private steps: TourStep[] = [];
  private _currentStep: BehaviorSubject<TourStep | null> =
    new BehaviorSubject<TourStep | null>(null);

  public constructor(
    private readonly _router: AppRouterService,
    private readonly _appState: AppStateService,
    private readonly _responsive: ResponsiveService
  ) {
    // Declare steps for guided tour
    this.steps = [
      {
        key: 'menu',
        type: 'all',
        content: [
          'Cliquez sur une thématique dans le menu à gauche ou sur les boutons centraux pour accéder aux sous-thèmes et aux graphiques correspondants',
        ],
        title: 'Thématiques',
        preCallback: (): Observable<null> => {
          this._appState.menuOpen = true;
          return fromPromise(this._router.navigate(Routes.home)).pipe(
            switchMap(() => of(null))
          );
        },
      },
      {
        key: 'themes',
        type: 'all',
        content: [
          'Cliquez sur une thématique dans le menu à gauche ou sur les boutons centraux pour accéder aux sous-thèmes et aux graphiques correspondants',
        ],
        title: 'Thématiques',
        preCallback: (): Observable<null> => {
          if (this._responsive.tablet || this._responsive.mobile) {
            this._appState.menuOpen = false;
          }
          return fromPromise(this._router.navigate(Routes.home)).pipe(
            switchMap(() => of(null))
          );
        },
      },
      {
        key: 'sub-menu',
        type: 'desktop',
        content: ['Cliquez sur le sous-thème de votre choix'],
        title: 'Sous-thèmes',
        preCallback: (): Observable<null> => {
          return fromPromise(this._router.navigate(Routes.companies)).pipe(
            tap(() => {
              this._appState.menuOpen = true;
              this._appState.noMenuAnimation = true;
              this._appState.menuFilterOpen = false;
              setTimeout(() => {
                this._appState.noMenuAnimation = false;
              }, 1000);
            }),
            switchMap(() => of(null))
          );
        },
      },
      {
        key: 'sub-theme-mobile',
        type: 'mobile',
        content: ['Cliquez sur le sous-thème de votre choix'],
        title: 'Sous-thèmes',
        preCallback: (): Observable<null> => {
          this._appState.menuFilterOpen = false;
          return fromPromise(this._router.navigate(Routes.companies)).pipe(
            debounceTime(200),
            switchMap(() => of(null))
          );
        },
      },
      {
        key: 'filters',
        type: 'desktop',
        content: [
          'Cliquez ici pour filtrer les données affichées selon le ou les critères de votre choix',
        ],
        title: 'Filtres',
        preCallback: (): Observable<null> => {
          if (this._responsive.tablet) {
            this._appState.menuOpen = false;
          }

          this._appState.menuFilterOpen = false;
          return of(null);
        },
      },
      {
        key: 'filters-mobile-button',
        type: 'mobile',
        content: ['Cliquez ici pour afficher les filtres'],
        title: 'Filtres',
        preCallback: (): Observable<null> => {
          this._appState.menuOpen = false;
          this._appState.menuFilterOpen = true;
          return of(null);
        },
      },
      {
        key: 'filters-mobile',
        type: 'mobile',
        content: [
          'Cliquez ici pour filtrer les données affichées selon le ou les critères de votre choix',
        ],
        title: 'Filtres',
        preCallback: (): Observable<null> => {
          this._appState.menuOpen = false;
          setTimeout(() => {
            this._appState.menuFilterOpen = true;
          });
          return of(null).pipe(debounceTime(300));
        },
      },
      {
        type: 'all',
        key: 'more-actions',
        content: [
          'Cliquez sur l’icône d’information pour obtenir des informations supplémentaires (source des données, périmètre…) ou sur l’icône de partage pour partager ou exporter les données et les graphiques',
        ],
        title: 'Autres actions',
        preCallback: (): Observable<null> => {
          this._appState.menuFilterOpen = true;
          return of(null);
        },
      },
    ];

    this._responsive.breakpoint$.subscribe(() => {
      // On change of breakpoint, we reload the current step
      this.reloadCurrentStep();
    });
  }

  public set currentStep(step: TourStep | null) {
    this._currentStep.next(step);
  }

  public get currentStep(): TourStep | null {
    return this._currentStep.value;
  }

  public get currentStep$(): Observable<TourStep | null> {
    return this._currentStep.asObservable();
  }

  private get compatibleSteps(): TourStep[] {
    return this.steps.filter((s: TourStep) => {
      return (
        s.type === 'all' ||
        (s.type === 'desktop' && !this._responsive.mobile) ||
        (s.type === 'mobile' && this._responsive.mobile)
      );
    });
  }

  public get indexStep(): number {
    if (this.currentStep) {
      return this.compatibleSteps.indexOf(this.currentStep);
    }

    return -1;
  }

  public get countStep(): number {
    return this.compatibleSteps.length;
  }

  /**
   * Start the guided tour
   */
  public startTour(): void {
    this.currentStep = null;
    this.nextStep();
  }

  /**
   * Stop the guided tour
   */
  public stopTour(returnHome: boolean = false): void {
    this.currentStep = null;
    if (returnHome) {
      this._router.navigate(Routes.home);
    }
  }

  /**
   * Go to the next step
   */
  public async nextStep(): Promise<void> {
    await this.restoreStep();
    if (this.indexStep === this.countStep - 1) {
      this.currentStep = null;
      return;
    }

    const nextStep: TourStep | null =
      this.compatibleSteps[this.indexStep + 1] ?? null;
    let obs: Observable<null> = of(null);
    if (nextStep?.preCallback) {
      obs = nextStep.preCallback();
    }

    obs.pipe(debounceTime(200)).subscribe(() => {
      this.currentStep = nextStep;
      if (!this.getStepElement(this.currentStep) && this.currentStep) {
        this.nextStep();
      }
    });
  }

  /**
   * Go to the previous step
   */
  public async previousStep(): Promise<void> {
    await this.restoreStep();
    if (this.indexStep > 0) {
      const previousStep: TourStep | null =
        this.compatibleSteps[this.indexStep - 1] ?? null;

      let obs: Observable<null> = of(null);
      if (previousStep?.preCallback) {
        obs = previousStep.preCallback();
      }
      obs.pipe(debounceTime(200)).subscribe(() => {
        this.currentStep = previousStep;
        if (!this.getStepElement(this.currentStep) && this.currentStep) {
          this.previousStep();
        }
      });
    }
  }

  /**
   * Register a step element from the directive to be used in the guided tour to highlight it
   */
  public registerStepElement(key: string, element: UiTourDirective): void {
    this.elements.set(key, element);
  }

  /**
   * Get the element of a step to highlight it
   * @param step
   */
  public getStepElement(step: TourStep): ElementRef | undefined {
    return this.elements.get(step.key)?.element;
  }

  /**
   * When going back, restore the previous step and run the callback
   */
  private async restoreStep(): Promise<void> {
    if (this.currentStep) {
      await this.currentStep.restoreCallBack?.()?.subscribe();
    }
  }

  /**
   * Reload completely the current step
   */
  private async reloadCurrentStep(): Promise<void> {
    let obs: Observable<null> = of(null);
    if (this.currentStep?.preCallback) {
      obs = this.currentStep.preCallback();
    }

    obs.pipe(debounceTime(200)).subscribe();
  }
}
