import { Component, HostBinding, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AppRouterService } from 'src/app/shared/services/app-router.service';
import { AbstractComponent } from '../../abstract.component';
import { ResponsiveService } from '../../../services/html/responsive.service';
import { AppStateService } from '../../../services/app-state.service';
import { Section } from '../../../enums/section.enum';
import { bodyScroll } from '../../../utils/html.utils';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends AbstractComponent implements OnInit {
  public current?: Section;

  public sections: typeof Section = Section;

  @HostBinding('class.expanded') public expanded: boolean = true;

  public close: boolean = false;
  public noAnimation: boolean = false;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _appRouter: AppRouterService,
    private readonly _responsive: ResponsiveService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._responsive.breakpoint$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        if (this._responsive.mobile || this._responsive.tablet) {
          this._appState.menuOpen = false;
        } else {
          this._appState.menuOpen = true;
        }
      });

    this._appState.menuOpen$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((open: boolean) => {
        this.expanded = open;
        if (this._responsive.mobile || this._responsive.tablet) {
          this.close = open;

          if (open) {
            bodyScroll(false);
          } else {
            bodyScroll(true);
          }
        }
      });

    this._appState.noMenuAnimation$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((noAnimation: boolean) => {
        this.noAnimation = noAnimation;
      });

    this._appState.currentSection$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((section: Section | undefined) => {
        this.current = section;
      });

    this._appRouter.navigationEnd$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        if (this._responsive.mobile) {
          this._appState.menuOpen = false;
        }
      });
  }
}
