import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { UiTourService } from '../services/ui-tour.service';

@Directive({
  selector: '[appUiTour]',
})
export class UiTourDirective implements OnInit {
  @Input() public appUiTour: string = '';

  public constructor(
    public readonly uiTour: UiTourService,
    public readonly element: ElementRef,
    public readonly viewContainer: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    this.uiTour.registerStepElement(
      this.appUiTour ?? this.element.nativeElement.id,
      this
    );
  }
}
