export abstract class Segments {
  public static readonly home: string = '';
  public static readonly companies: string = 'etablissements';
  public static readonly employees: string = 'salaries';
  public static readonly workStudy: string = 'formation-alternance';
  public static readonly apprentices: string = 'contrats-apprentissage';
  public static readonly professionals: string =
    'contrats-professionnalisation';
  public static readonly interns: string = 'formation-continue';
  public static readonly selfEmployed: string = 'tns';
  public static readonly students: string = 'formation-initiale';
  public static readonly evolution: string = 'evolution';
  public static readonly evolutionWomen: string = 'evolution-femmes';
  public static readonly notFound: string = 'non-trouvee';
  public static readonly activity: string = 'activite';
  public static readonly sector: string = 'branche';
  public static readonly size: string = 'taille';
  public static readonly location: string = 'localisation';
  public static readonly category: string = 'thematique';
  public static readonly degree: string = 'diplome';
  public static readonly age: string = 'age';
  public static readonly speciality: string = 'specialite';
  public static readonly gender: string = 'sexe';
  public static readonly job: string = 'metier';
  public static readonly jobEvolution: string = 'metier-evolution';
  public static readonly legalNotice: string = 'mentions-legales';
  public static readonly keyFigures: string = 'chiffres-cles';
}

export abstract class Routes {
  public static readonly home: string = Segments.home;

  public static readonly companies: string = Segments.companies;
  public static readonly companiesEvolution: string = [
    Segments.companies,
    Segments.evolution,
  ].join('/');
  public static readonly companiesActivity: string = [
    Segments.companies,
    Segments.activity,
  ].join('/');
  public static readonly companiesSector: string = [
    Segments.companies,
    Segments.sector,
  ].join('/');
  public static readonly companiesSize: string = [
    Segments.companies,
    Segments.size,
  ].join('/');
  public static readonly companiesLocation: string = [
    Segments.companies,
    Segments.location,
  ].join('/');

  public static readonly employees: string = Segments.employees;
  public static readonly employeesEvolutionWomen: string = [
    Segments.employees,
    Segments.evolutionWomen,
  ].join('/');
  public static readonly employeesActivity: string = [
    Segments.employees,
    Segments.activity,
  ].join('/');
  public static readonly employeesGender: string = [
    Segments.employees,
    Segments.gender,
  ].join('/');
  public static readonly employeesAge: string = [
    Segments.employees,
    Segments.age,
  ].join('/');
  public static readonly employeesLocation: string = [
    Segments.employees,
    Segments.location,
  ].join('/');
  public static readonly employeesJob: string = [
    Segments.employees,
    Segments.job,
  ].join('/');
  public static readonly employeesJobEvolution: string = [
    Segments.employees,
    Segments.jobEvolution,
  ].join('/');

  public static readonly apprentices: string = [
    Segments.workStudy,
    Segments.apprentices,
  ].join('/');
  public static readonly apprenticesEvolution: string = [
    Segments.workStudy,
    Segments.apprentices,
    Segments.evolution,
  ].join('/');
  public static readonly apprenticesDegree: string = [
    Segments.workStudy,
    Segments.apprentices,
    Segments.degree,
  ].join('/');
  public static readonly apprenticesSector: string = [
    Segments.workStudy,
    Segments.apprentices,
    Segments.sector,
  ].join('/');
  public static readonly apprenticesActivity: string = [
    Segments.workStudy,
    Segments.apprentices,
    Segments.activity,
  ].join('/');
  public static readonly apprenticesAge: string = [
    Segments.workStudy,
    Segments.apprentices,
    Segments.age,
  ].join('/');
  public static readonly professionals: string = [
    Segments.workStudy,
    Segments.professionals,
  ].join('/');
  public static readonly professionalsEvolution: string = [
    Segments.workStudy,
    Segments.professionals,
    Segments.evolution,
  ].join('/');
  public static readonly professionalsSector: string = [
    Segments.workStudy,
    Segments.professionals,
    Segments.sector,
  ].join('/');
  public static readonly professionalsActivity: string = [
    Segments.workStudy,
    Segments.professionals,
    Segments.activity,
  ].join('/');
  public static readonly professionalsAge: string = [
    Segments.workStudy,
    Segments.professionals,
    Segments.age,
  ].join('/');

  public static readonly interns: string = Segments.interns;
  public static readonly internsEvolution: string = [
    Segments.interns,
    Segments.evolution,
  ].join('/');
  public static readonly internsCategory: string = [
    Segments.interns,
    Segments.category,
  ].join('/');

  public static readonly selfEmployed: string = Segments.selfEmployed;
  public static readonly selfEmployedCategory: string = [
    Segments.selfEmployed,
    Segments.category,
  ].join('/');
  public static readonly selfEmployedKeyFigures: string = [
    Segments.selfEmployed,
    Segments.keyFigures,
  ].join('/');

  public static readonly students: string = Segments.students;
  public static readonly legalNotice: string = Segments.legalNotice;
  public static readonly notFound: string = Segments.notFound;
}
