import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';

@Injectable()
export class QueryStateInterceptor implements HttpInterceptor {
  private _queue: number = 0;

  public constructor(private readonly _appState: AppStateService) {}

  /**
   * Update the app state when a query fire and resolves
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this._queue++;
    this.update();

    return next.handle(request).pipe(
      finalize(() => {
        this._queue--;
        this.update();
      })
    );
  }

  private update(): void {
    this._appState.loading = this._queue;
  }
}
