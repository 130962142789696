import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Routes } from '../constants/routes/routes.constant';

@Component({ template: '' })
export abstract class AbstractComponent implements OnDestroy {
  public readonly routes: typeof Routes = Routes;
  protected _destroyed$: Subject<void> = new Subject<void>();

  public get destroyed$(): Observable<void> {
    return this._destroyed$.asObservable();
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
