import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Settings } from '../../constants/settings.constant';
import { AppRouterService } from '../app-router.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  public constructor(
    private readonly _appRouter: AppRouterService,
    private readonly _title: Title,
    private readonly _meta: Meta,
    private readonly _config: ConfigService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public set title(value: string | undefined) {
    const title: string =
      (value ?? Settings.defaultTitle) + ' | ' + Settings.appName;
    this._title.setTitle(title);

    if (value) {
      this._meta.updateTag({ name: 'og:title', content: title });
    } else {
      this._meta.removeTag('name="og:title"');
    }
  }

  public set description(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'description', content: value });
      this._meta.updateTag({ name: 'og:description', content: value });
    } else {
      this._meta.removeTag('name="description"');
      this._meta.removeTag('name="og:description"');
    }
  }

  public set keywords(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'keywords', content: value });
    } else {
      this._meta.removeTag('name="keywords"');
    }
  }

  public set author(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'author', content: value });
    } else {
      this._meta.removeTag('name="author"');
    }
  }

  public set image(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'og:image', content: value });
    } else {
      this._meta.removeTag('name="og:image"');
    }
  }

  public set robots(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'robots', content: value });
    } else {
      this._meta.removeTag('name="robots"');
    }
  }

  public set lang(value: string) {
    this._document.documentElement.lang = value;
    this._meta.updateTag({ name: 'og:locale', content: value });
  }

  public bootstrap(): void {
    this.lang = Settings.lang;
    this._meta.updateTag({ name: 'og:type', value: 'website' });
    this._meta.updateTag({
      name: 'og:site_name',
      value: Settings.defaultTitle,
    });
  }

  public setCanonicalURL(href?: string): void {
    href = this._config.configuration?.url + (href ?? this._appRouter.route);
    let link: HTMLLinkElement = this._document.head.querySelectorAll(
      'link[rel="canonical"]'
    )?.[0] as HTMLLinkElement;

    // If the tag do not exists, create one
    if (!link) {
      link = this._document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this._document.head.appendChild(link);
    }

    // Set the canonical url with the current url but remove the query params
    link.setAttribute('href', href);

    // Set the og url
    this._meta.updateTag({ name: 'og:url', value: href });
  }

  public clear(): void {
    this.title = undefined;
    this.description = undefined;
    this.keywords = undefined;
    this.author = undefined;
    this.image = undefined;
    this.robots = undefined;
  }
}
