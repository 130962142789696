<span
  class="inner"
  appRipple
  appRippleColor="rgba(255, 255, 255, 0.8)"
  [ngClass]="{ border, large }">
  <app-icon [src]="src ?? ''" [alt]="alt"></app-icon>
  @if (badge) {
    <span class="badge" [ngClass]="badgeColor">{{ badge }}</span>
  }
</span>
