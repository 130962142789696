import { StorageKeys } from '../../enums/storage-keys.enum';

export const storageKey = (key: string): string => {
  return StorageKeys.prefix ? `${StorageKeys.prefix}-${key}` : key;
};

export interface StorageService {
  get(key: string): string | null;

  set(key: string, value: string): void;

  remove(key: string): void;

  clear(): void;
}
