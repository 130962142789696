import { Injectable } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
} from '@angular/router';
import { Observable, filter } from 'rxjs';
import { RequestParams } from '../models/sys/routing';

@Injectable({
  providedIn: 'root',
})
export class AppRouterService {
  public constructor(private readonly _router: Router) {}

  public get url(): string {
    return this._router.routerState.snapshot.url;
  }

  public get route(): string {
    return this.url.split('?')[0];
  }

  /**
   * Url changed, will trigger guards/resolvers
   */
  public get navigationStart$(): Observable<NavigationStart> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof NavigationStart)
    ) as Observable<NavigationStart>;
  }

  /**
   * Navigation ended, guards/resolvers resolved
   */
  public get navigationEnd$(): Observable<NavigationEnd> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  /**
   * Proper navigation with params and attributes
   */
  public navigate(
    url: string,
    params?: RequestParams,
    attrs?: RequestParams
  ): Promise<boolean> {
    if (params) {
      Object.keys(params).forEach((key: string) => {
        if (params[key] instanceof Date) {
          // Date iso formatting
          params[key] = (params[key] as Date).toISOString();
        } else if (
          Array.isArray(params[key]) &&
          (params[key] as unknown[]).length === 0
        ) {
          // Arrays cleanup
          params[key] = undefined;
        }
      });
    }

    const route: string = this.prepareRoute(url, attrs);
    const extras: NavigationExtras = {};
    if (params) {
      extras.queryParams = params;
    }

    extras.replaceUrl =
      (url.indexOf('?') !== -1 && window.location.href.indexOf('?') === -1) ||
      window.location.href.endsWith(url);

    return this._router.navigate(
      [route],
      Object.keys(extras).length ? extras : undefined
    );
  }

  /**
   * Navigation change with params update only
   */
  public setParams(params?: RequestParams): Promise<boolean> {
    return this.navigate(this.route, params);
  }

  /**
   * Params mapping
   */
  public prepareRoute(route: string, attrs?: RequestParams): string {
    route = route as unknown as string;
    attrs = attrs ?? {};

    for (const key in attrs) {
      if (attrs[key] !== undefined && attrs[key] !== null) {
        route = route.replace(':' + key, this.normalize(attrs[key]));
      }
    }

    return (!route.startsWith('/') ? '/' : '') + route;
  }

  /**
   * Array param mapping
   */
  public getParamsToArray(param: unknown, number: boolean = false): unknown[] {
    if (param !== undefined) {
      if (Array.isArray(param)) {
        return param.map((value: string) =>
          number ? parseInt(value, 10) : value
        );
      } else {
        return [number ? parseInt(param as string, 10) : param];
      }
    } else {
      return [];
    }
  }

  /**
   * Url attribute normalization (gets rid of accents, special chars, etc.)
   */
  private normalize(value: unknown): string {
    return (<string>value + '').trim().normalize('NFD');
  }
}
