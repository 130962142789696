<app-popin-layout
  icon="assets/images/icons/info.svg"
  [label]="options.title"
  (dismiss)="close(false)">
  <main>
    {{ options.content }}
  </main>

  <div class="controls">
    <button app-button color="grey" (click)="close(false)">
      {{ options.cancel }}
    </button>
    <button app-button color="orange" (click)="close(true)">
      {{ options.validate }}
    </button>
  </div>
</app-popin-layout>
