import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Configuration } from '../models/sys/configuration';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config: BehaviorSubject<Configuration | null> =
    new BehaviorSubject<Configuration | null>(null);

  public constructor(private readonly _http: HttpClient) {}

  public get configuration(): Configuration | null {
    return this._config.value;
  }

  public bootstrap(): Observable<null> {
    return this._http.get<Configuration>('/assets/config/app.json').pipe(
      catchError(() => of(environment)),
      tap((config: Configuration) => {
        this._config.next({ ...environment, ...config } as Configuration);
      }),
      map(() => null)
    );
  }
}
