import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TourStep, UiTourService } from '../../../services/ui-tour.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { ResponsiveService } from '../../../services/html/responsive.service';
import { BehaviorSubject, takeUntil, throttleTime } from 'rxjs';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'app-ui-tour',
  templateUrl: './ui-tour.component.html',
  styleUrl: './ui-tour.component.scss',
  imports: [NgStyle, NgIf, NgClass, SharedModule],
  standalone: true,
})
export class UiTourComponent extends AbstractComponent implements OnInit {
  @Input() public step?: TourStep | null;
  @ViewChild('tour') public tour!: ElementRef;
  private interval?: ReturnType<typeof setTimeout>;
  private frame: number = 0;

  private updatePosition$: BehaviorSubject<Event | null> =
    new BehaviorSubject<Event | null>(null);

  @HostListener('window:resize', ['$event'])
  public onResize($event: Event): void {
    this.updatePosition$.next($event);
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll($event: Event): void {
    this.updatePosition$.next($event);
  }

  // Infos for the tooltip
  public top?: string = undefined;
  public left: string = '0';
  public bottom?: string = undefined;
  public class: 'right' | 'left' | 'bottom' | 'top' | 'center' = 'right';
  public index: number = 0;
  public max: number = 0;

  // Infos for the highlight box
  public highlightLeft: number = 0;
  public highlightTop: number = 0;
  public highlightWidth: number = 0;
  public highlightHeight: number = 0;

  // Constants for tooltip dimensions
  public static readonly width: number = 300;
  public static readonly height: number = 250;
  public static readonly gap: number = 15;

  public constructor(
    private readonly _ui: UiTourService,
    private readonly _responsive: ResponsiveService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updatePosition$
      .pipe(takeUntil(this.destroyed$), throttleTime(100))
      .subscribe(() => {
        this.updatePosition();
      });

    this._ui.currentStep$.subscribe((step: TourStep | null) => {
      if (step) {
        // Scroll to the element
        const element: ElementRef | undefined = this._ui.getStepElement(step);
        element?.nativeElement.scrollIntoView({ block: 'center' });

        // Update the step
        this.step = step;
        this.index = this._ui.indexStep;
        this.max = this._ui.countStep;

        // Update the position
        this.frame = 0;
        this.interval = setInterval(() => {
          this.frame++;
          if (this.frame >= 50 && this.interval) {
            clearInterval(this.interval);
          }
          this.updatePosition();
        }, 10);
      } else {
        this.step = null;
      }
    });
  }

  /**
   * Update tooltip position depending on screen size and element to highlight
   * @private
   */
  private updatePosition(): void {
    if (this.step) {
      const element: ElementRef | undefined = this._ui.getStepElement(
        this.step
      );
      if (element) {
        const rect: DOMRect = element.nativeElement.getBoundingClientRect();
        this.highlightLeft = rect.left - 5;
        this.highlightTop = rect.top - 5;
        this.highlightWidth = rect.width + 10;
        this.highlightHeight = rect.height + 10;
        this.bottom = undefined;

        // check the bounding rect of the element to defined the best position for the tooltip
        if (!this._responsive.mobile) {
          if (this.displayBelow(rect)) {
            return;
          }

          if (this.displayRight(rect)) {
            return;
          }

          if (this.displayLeft(rect)) {
            return;
          }

          if (this.displayAbove(rect)) {
            return;
          }

          // Position in the center of the screen
          this.top = `calc(50% - ${UiTourComponent.height / 2}px)`;
          this.left = `calc(50% - ${UiTourComponent.width / 2}px)`;
          this.class = 'center';
        } else {
          // In mobile position in the bottom center of the screen
          this.top = undefined;
          this.bottom = '50px';
          this.left = `calc(50% - ${UiTourComponent.width / 2}px)`;
          this.class = 'center';
        }
      }
    }
  }

  /**
   * Close the step and stop the guided tour
   */
  public close(returnHome: boolean): void {
    this._ui.stopTour(returnHome);
  }

  /**
   * Navigate to the next step
   */
  public next(): void {
    this._ui.nextStep();
  }

  /**
   * Go back to the previous step
   */
  public previous(): void {
    this._ui.previousStep();
  }

  /**
   * Display the tooltip below the element if possible
   * @param rect
   * @private
   */
  private displayBelow(rect: DOMRect): boolean {
    const top: number = rect.bottom + UiTourComponent.gap;
    const left: number = rect.left + rect.width / 2 - UiTourComponent.width / 2;
    const right: number = left + UiTourComponent.width;
    const bottom: number = top + UiTourComponent.height;
    if (
      bottom < window.innerHeight &&
      left >= 0 &&
      right <= window.innerWidth
    ) {
      this.top = `${Math.floor(top)}px`;
      this.left = `${Math.floor(left)}px`;
      this.class = 'bottom';
      return true;
    }
    return false;
  }

  /**
   * Display the tooltip on the right of the element if possible
   * @param rect
   * @private
   */
  private displayRight(rect: DOMRect): boolean {
    const top: number = rect.top + UiTourComponent.gap;
    const left: number = rect.right + UiTourComponent.gap;
    const right: number = left + UiTourComponent.width;
    if (right <= window.innerWidth && top >= 0 && left >= 0) {
      this.left = `${Math.floor(left)}px`;
      this.top = `${Math.floor(top)}px`;
      this.class = 'right';
      return true;
    }

    return false;
  }

  /**
   * Display the tooltip on the left of the element if possible
   * @param rect
   * @private
   */
  private displayLeft(rect: DOMRect): boolean {
    const top: number = rect.top + UiTourComponent.gap;
    const left: number =
      rect.left - UiTourComponent.width - UiTourComponent.gap;
    const right: number = left + UiTourComponent.width;

    if (left >= 0 && right <= window.innerWidth && top >= 0) {
      this.left = `${Math.floor(left)}px`;
      this.top = `${Math.floor(top)}px`;
      this.class = 'left';
      return true;
    }

    return false;
  }

  /**
   * Display the tooltip above the element if possible
   * @param rect
   * @private
   */
  private displayAbove(rect: DOMRect): boolean {
    const top: number = rect.top - UiTourComponent.height - UiTourComponent.gap;
    const left: number = rect.left + rect.width / 2 - UiTourComponent.width / 2;
    const right: number = left + UiTourComponent.width;

    if (top >= 0 && left >= 0 && right <= window.innerWidth) {
      this.top = `${Math.floor(top)}px`;
      this.left = `${Math.floor(left)}px`;
      this.class = 'top';
      return true;
    }

    return false;
  }
}
