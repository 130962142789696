import { CookieOptions } from '../services/storage/cookies-storage.service';

export abstract class Settings {
  public static readonly lang: string = 'fr-fr';
  public static readonly appName: string = 'Observatoire des métiers du BTP';
  public static readonly defaultTitle: string = 'Les chiffres clés du BTP';

  public static readonly filterParams: string = 'f_';
  public static readonly compareParams: string = 'c_';
  public static readonly comparisonParam: string = 'comparaison';

  public static readonly tmpStorageExpiration: number = 3600;

  public static readonly defaultSnackDuration: number = 5000;

  public static readonly cookieOptions: CookieOptions = {
    secure: true,
    httpOnly: false,
    sameSite: 'Strict',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  };
}
