import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractComponent } from 'src/app/shared/components/abstract.component';
import {
  SnackbarService,
  SnackItem,
  SnackType,
} from '../../../../services/snackbar.service';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss'],
})
export class SnackComponent
  extends AbstractComponent
  implements OnChanges, OnInit
{
  @Input({ required: true }) public data?: SnackItem;

  @Output() public clear: EventEmitter<void>;

  public classes: string[] = [];
  public icon: string | null = null;

  public visible: boolean = false;

  private _hideTimeout?: NodeJS.Timeout;
  private _animationTimeout?: NodeJS.Timeout;

  public readonly animation: number = 300;

  public constructor(private readonly _snack: SnackbarService) {
    super();

    this.clear = new EventEmitter<void>();
  }

  public ngOnInit(): void {
    this._snack.closeAll$.subscribe(() => {
      this.hide();
    });
  }

  public ngOnChanges(): void {
    const classes: string[] = ['snack'];
    let icon: string | null = null;
    switch (this.data?.type) {
      case SnackType.Success:
        icon = 'assets/images/icons/success.svg';
        classes.push('success');
        break;
      case SnackType.Error:
        icon = 'assets/images/icons/error.svg';
        classes.push('error');
        break;
      case SnackType.Info:
        icon = 'assets/images/icons/info.svg';
        classes.push('info');
        break;
      case SnackType.Warning:
        icon = 'assets/images/icons/info.svg';
        classes.push('warning');
        break;
    }

    this.icon = icon;
    this.classes = classes;
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    });

    this.clearTimeouts();
    if (this.data?.duration) {
      this._hideTimeout = setTimeout(() => {
        this.hide();
      }, this.data.duration);
    }
  }

  public hide(): void {
    this.clearTimeouts();
    this.visible = false;
    this._animationTimeout = setTimeout(() => {
      this.clear.emit();
    }, this.animation * 2);
  }

  public clearTimeouts(): void {
    clearTimeout(this._hideTimeout);
    clearTimeout(this._animationTimeout);
  }
}
