import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BootstrapDto } from '../models/dto/bootstrap-dto';
import { NavigationEnd, Params } from '@angular/router';
import { Metadata } from '../models/sys/metadata';
import { Segments } from '../constants/routes/routes.constant';
import { AppRouterService } from './app-router.service';
import { Section } from '../enums/section.enum';
import { DepartmentDto } from '../models/dto/department-dto';
import { RegionDto } from '../models/dto/region-dto';
import { SectorDto } from '../models/dto/sector-dto';
import { ActivityDto } from '../models/dto/activity-dto';
import { CompanySizeDto } from '../models/dto/company-size-dto';
import { HomeDto } from '../models/dto/home-dto';
import { SessionStorageService } from './storage/session-storage.service';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private readonly _loading$: BehaviorSubject<number>;
  private readonly _globalFilters$: BehaviorSubject<BootstrapDto | undefined>;
  private readonly _activeParams$: BehaviorSubject<Params | null>;
  private readonly _metadata$: BehaviorSubject<Metadata | undefined>;
  private readonly _menuOpen$: BehaviorSubject<boolean>;
  private readonly _menuFilterOpen$: BehaviorSubject<boolean>;
  private readonly _currentSection$: BehaviorSubject<number | undefined>;
  private readonly _homeFigures$: BehaviorSubject<HomeDto | undefined>;
  private readonly _noMenuAnimation$: BehaviorSubject<boolean>;

  private readonly LS_FILTERS: string = 'dataviz_filters';
  private readonly LS_TOUR: string = 'dataviz_tour';

  public activeSection: Section | undefined;
  private _hasTakenTour: boolean = false;

  public constructor(
    private readonly _appRouter: AppRouterService,
    private readonly _session: SessionStorageService,
    private readonly _local: LocalStorageService
  ) {
    this._loading$ = new BehaviorSubject<number>(0);
    this._metadata$ = new BehaviorSubject<Metadata | undefined>(undefined);
    this._menuOpen$ = new BehaviorSubject<boolean>(false);
    this._menuFilterOpen$ = new BehaviorSubject<boolean>(false);
    this._currentSection$ = new BehaviorSubject<Section | undefined>(undefined);
    this._activeParams$ = new BehaviorSubject<Params | null>(
      this._session.get(this.LS_FILTERS)
        ? JSON.parse(this._session.get(this.LS_FILTERS)!)
        : null
    );
    this._globalFilters$ = new BehaviorSubject<BootstrapDto | undefined>(
      undefined
    );
    this._homeFigures$ = new BehaviorSubject<HomeDto | undefined>(undefined);
    this._noMenuAnimation$ = new BehaviorSubject<boolean>(false);
    this._hasTakenTour = this._local.get(this.LS_TOUR) === 'true';

    this._appRouter.navigationEnd$.subscribe((e: NavigationEnd) => {
      this._currentSection$.next(this.getSectionFromUrl(e.url));
    });
  }

  public get loading(): number {
    return this._loading$.getValue();
  }

  public set loading(value: number) {
    this._loading$.next(value);
  }

  public get loading$(): Observable<number> {
    return this._loading$.asObservable();
  }

  public get metadata(): Metadata | undefined {
    return this._metadata$.getValue();
  }

  public set metadata(value: Metadata | undefined) {
    this._metadata$.next(value);
  }

  public get metadata$(): Observable<Metadata | undefined> {
    return this._metadata$.asObservable();
  }

  public get menuOpen(): boolean {
    return this._menuOpen$.getValue();
  }

  public set menuOpen(value: boolean) {
    this._menuOpen$.next(value);
  }

  public get menuOpen$(): Observable<boolean> {
    return this._menuOpen$.asObservable();
  }

  public get menuFilterOpen(): boolean {
    return this._menuFilterOpen$.getValue();
  }

  public set menuFilterOpen(value: boolean) {
    this._menuFilterOpen$.next(value);
  }

  public get menuFilterOpen$(): Observable<boolean> {
    return this._menuFilterOpen$.asObservable();
  }

  public get currentSection(): Section | undefined {
    return this._currentSection$.getValue();
  }

  public get currentSection$(): Observable<Section | undefined> {
    return this._currentSection$.asObservable();
  }

  private getSectionFromUrl(url: string): Section | undefined {
    switch (true) {
      case url.startsWith('/' + Segments.companies):
        return Section.companies;
      case url.startsWith('/' + Segments.employees):
        return Section.employees;
      case url.startsWith('/' + Segments.interns):
        return Section.interns;
      case url.startsWith('/' + Segments.workStudy):
        return Section.workStudy;
      case url.startsWith('/' + Segments.students):
        return Section.students;
      case url.startsWith('/' + Segments.selfEmployed):
        return Section.selfEmployed;
      default:
        return undefined;
    }
  }

  public get globalFilters(): BootstrapDto | undefined {
    return this._globalFilters$.getValue();
  }

  public set globalFilters(value: BootstrapDto | undefined) {
    this._globalFilters$.next(value);
  }

  public get globalFilters$(): Observable<BootstrapDto | undefined> {
    return this._globalFilters$.asObservable();
  }

  public get activeParams(): Params | null {
    return this._activeParams$.getValue();
  }

  public set activeParams(value: Params) {
    this._session.set(this.LS_FILTERS, JSON.stringify(value ?? {}));
    this._activeParams$.next(value);
  }

  public get activeParams$(): Observable<Params | null> {
    return this._activeParams$.asObservable();
  }

  public get homeFigures(): HomeDto | undefined {
    return this._homeFigures$.getValue();
  }

  public set homeFigures(value: HomeDto | undefined) {
    this._homeFigures$.next(value);
  }

  public get homeFigures$(): Observable<HomeDto | undefined> {
    return this._homeFigures$.asObservable();
  }

  public getDepartement(id: number): DepartmentDto | undefined {
    return (this.globalFilters?.departments ?? []).find(
      (d: DepartmentDto) => d.id === id
    );
  }

  public getRegion(id: number): RegionDto | undefined {
    return (this.globalFilters?.regions ?? []).find(
      (d: RegionDto) => d.id === id
    );
  }

  public getSector(id: number): SectorDto | undefined {
    return (this.globalFilters?.sectors ?? []).find(
      (d: SectorDto) => d.id === id
    );
  }

  public getSize(id: number): CompanySizeDto | undefined {
    return (this.globalFilters?.companySizes ?? []).find(
      (d: CompanySizeDto) => d.id === id
    );
  }

  public getActivity(code: string): ActivityDto | undefined {
    return (this.globalFilters?.activities ?? []).find(
      (d: ActivityDto) => d.code === code
    );
  }

  public getRegionByDepartementId(id: number): RegionDto | undefined {
    const department: DepartmentDto | undefined = this.getDepartement(id);
    if (department) {
      return this.getRegion(department.regionId);
    }

    return undefined;
  }

  public get hasTakenTour(): boolean {
    return this._hasTakenTour;
  }

  public set hasTakenTour(value: boolean) {
    this._local.set(this.LS_TOUR, value.toString());
    this._hasTakenTour = value;
  }

  public get noMenuAnimation(): boolean {
    return this._noMenuAnimation$.value;
  }

  public get noMenuAnimation$(): Observable<boolean> {
    return this._noMenuAnimation$.asObservable();
  }

  public set noMenuAnimation(value: boolean) {
    this._noMenuAnimation$.next(value);
  }
}
