import { Component, Input } from '@angular/core';

export type ButtonColor = 'orange' | 'grey';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'a[app-button], button[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public color?: ButtonColor;
}
