export abstract class Webservices {
  public static readonly bootstrap: string = '/bootstrap';
  public static readonly home: string = '/home';
  public static readonly internshipFilter: string = '/internship/filters';
  public static readonly internshipEvolution: string = '/internship/evolution';
  public static readonly internshipEvolutionExport: string =
    '/internship/evolution/export';
  public static readonly internshipByCategory: string = '/internship/category';
  public static readonly internshipByCategoryExport: string =
    '/internship/category/export';
  public static readonly companyFilter: string = '/company/filters';
  public static readonly companyActivity: string = '/company/activity';
  public static readonly companyActivityExport: string =
    '/company/activity/export';
  public static readonly companySector: string = '/company/sector';
  public static readonly companySectorExport: string = '/company/sector/export';
  public static readonly companySize: string = '/company/size';
  public static readonly companySizeExport: string = '/company/size/export';
  public static readonly companyLocation: string = '/company/location';
  public static readonly companyLocationExport: string =
    '/company/location/export';
  public static readonly studentFilter: string = '/student/filters';
  public static readonly studentSpeciality: string = '/student';
  public static readonly studentSpecialityExport: string = '/student/export';
  public static readonly employeeFilter: string = '/employee/filters';
  public static readonly employeeWomenEvolution: string =
    '/employee/evolution/women';
  public static readonly employeeEvolutionWomenExport: string =
    '/employee/evolution/women/export';
  public static readonly employeeActivity: string = '/employee/activity';
  public static readonly employeeActivityExport: string =
    '/employee/activity/export';
  public static readonly employeeGender: string = '/employee/gender';
  public static readonly employeeGenderExport: string =
    '/employee/gender/export';
  public static readonly employeeAge: string = '/employee/age';
  public static readonly employeeAgeExport: string = '/employee/age/export';
  public static readonly employeeLocation: string = '/employee/location';
  public static readonly employeeLocationExport: string =
    '/employee/location/export';
  public static readonly employeeJobEvolution: string =
    '/employee/evolution/job';
  public static readonly employeeEvolutionJobExport: string =
    '/employee/evolution/job/export';
  public static readonly employeeByJob: string = '/employee/job';
  public static readonly employeeByJobExport: string = '/employee/job/export';
  public static readonly apprenticeshipFilter: string =
    '/apprenticeship/filters';
  public static readonly apprenticeshipEvolution: string =
    '/apprenticeship/:type/evolution';
  public static readonly apprenticeshipEvolutionExport: string =
    '/apprenticeship/:type/evolution/export';
  public static readonly apprenticeshipByDegree: string =
    '/apprenticeship/:type/degree';
  public static readonly apprenticeshipByDegreeExport: string =
    '/apprenticeship/:type/degree/export';
  public static readonly apprenticeshipBySector: string =
    '/apprenticeship/:type/sector';
  public static readonly apprenticeshipBySectorExport: string =
    '/apprenticeship/:type/sector/export';
  public static readonly apprenticeshipByActivity: string =
    '/apprenticeship/:type/activity';
  public static readonly apprenticeshipByActivityExport: string =
    '/apprenticeship/:type/activity/export';
  public static readonly apprenticeshipByAge: string =
    '/apprenticeship/:type/age';
  public static readonly apprenticeshipByAgeExport: string =
    '/apprenticeship/:type/age/export';
  public static readonly selfEmployedFilter: string = '/self-employed/filters';
  public static readonly selfEmployedTheme: string = '/self-employed/theme';
  public static readonly selfEmployedThemeExport: string =
    '/self-employed/theme/export';
  public static readonly selfEmployedStatus: string = '/self-employed/status';
  public static readonly selfEmployedSex: string = '/self-employed/sex';
  public static readonly selfEmployedType: string = '/self-employed/type';
  public static readonly selfEmployedKeyFigures: string =
    '/self-employed/key-figures';
  public static readonly selfEmployedKeyFiguresExport: string =
    '/self-employed/key-figures/export';
}
