import { Component, HostListener, Input } from '@angular/core';
import { AbstractComponent } from 'src/app/shared/components/abstract.component';
import { AppStateService } from '../../../services/app-state.service';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'button[app-burger]',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
})
export class BurgerComponent extends AbstractComponent {
  @Input() public close: boolean = false;

  @HostListener('click') public onClick(): void {
    this._appState.menuOpen = !this._appState.menuOpen;
  }

  public constructor(private readonly _appState: AppStateService) {
    super();
  }
}
